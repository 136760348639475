import React, { useEffect, useState } from 'react'
import io from "socket.io-client"
import "./QrLogin.css"
import QRCode from "react-qr-code";
const socket = io.connect("https://whatsappotpbackend.videoencryptor.com/", {}) 

const QrLogin = () => {

const [session, setSession] = useState("");
const [qrCode, setQrCode] = useState("");
const [error, setError] = useState("");

const createSessionForWhatsapp = () => {
  socket.emit("createSession", {
    id: session
  })
}

const [id, setId] = useState("")

useEffect(() => {
  
  socket.emit("connected", "Hello form client");
  socket.on("qr", (data) => {
    const {qr} = data;
    console.log("QR RECEIVE", qr);
    setQrCode(qr)
  })
  
  socket.on("ready", (data) => {
    console.log(data);
    const {id} = data;
    setId(id)
  })
  
  socket.on("allChats", (data) => {
    console.log("allChats : ", data);
  })
  
  socket.on("error", (data) => {
    console.log(data);
    setError(data);
  })
}, [])

const getAllChats = () => {
  socket.emit("getAllChats", {id})
}

  return (
    <div className='flex justify-center flex-col items-center'>
      <h1 className='my-2 text-lg'>Whatsapp web qr code</h1>
      <h1 className='font-bold text-xl'>Open whatsapp and scan qr code</h1>
      <div className='m-4'>
        <input 
        className='border-solid border-2 border-sky-500'
        type="text" 
        value={session}
        onChange={(e) => {
          setSession(e.target.value)
        }}
        />
        <button className='bg-slate-100	rounded p-1' onClick={createSessionForWhatsapp}>Create session</button>
      </div>
      <div style={error ? {} : {display: 'none'}} className='text-red-400'>
        {error}
      </div>
      <div className='mb-4'>
          {id !== "" && <button onClick={getAllChats} >Get all chats</button>}
      </div>
      <QRCode className='my-4' value={qrCode}/>
    </div>
  )
}

export default QrLogin