import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import QrLogin from "./pages/QrLogin";


function App() {
  return (
    
    <Routes>
      <Route exact path="/" element={<QrLogin/>} />
      <Route path="/home" element={<Home/>} />
    </Routes>
    
  );
}

export default App;
